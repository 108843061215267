import React from "react"
import Link from "../components/link"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import Gallery from "../components/gallery"
// import Newsletter from "../components/newsletter"

const insideStyles = {
  position: "relative",
  top: "-20vh",
  textAlign: "center",
}

const IndexPage = () => (
  <Layout>
    <Seo />
    <StaticImage
      src="../images/salon-interior.jpg"
      alt="Inside Pure Alchemy Organic Salon"
      layout="fullWidth"
      placeholder="none"
      backgroundColor="rgb(248, 222, 255)"
      style={{
        height: `40vh`,
        minHeight: `300px`,
      }}
    />
    <div style={insideStyles}>
      <Link className="button" to="/book-now" style={{ fontSize: "1.2rem" }}>
        Book Now
      </Link>
    </div>
    <div className="frame_one_column">
      <div className="centered_p">
        <h1 style={{ textAlign: `center` }}>Magic is in the Hair</h1>
        <p>Conjuring the best you in Columbia City, Seattle!</p>
        <p>
          <strong>I am a curly and kinky hair specialist,</strong> and offer
          the full range of hair services from cuts to high fashion color to
          fully reveal your beautiful self.
        </p>
        <p>Recently moved to Pure Alchemy Organic Salon, where you can find me
          on Fridays, Saturdays and Sundays!
        </p>
        <div className="button_p">
          <a
            className="button"
            property="url"
            href="https://www.instagram.com/bruja_hair/"
          >
            Instagram
          </a>
        </div>
      </div>
    </div>
    <div className="frame_two_columns">
      {/* <Gallery /> */}
      {/* <Newsletter /> */}
    </div>
  </Layout>
)

export default IndexPage
